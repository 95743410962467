import { useMemo, useState } from 'react'
import {
  DocumentTextIcon,
  EnvelopeIcon,
  LockClosedIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline'
import { formatDate } from '@/utils/date'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { UseDashboardResponse } from './useDashboardData.types'
import useBillingApi from '../api/billing/useBillingApi/useBillingApi'
import useTranslation from '../useTranslation'
import useDashboardApi from '@/hooks/api/dashboards/useDashboardApi/useDashboardApi'

const useDashboardData = (): UseDashboardResponse => {
  const { t } = useTranslation('dashboardPage')
  const { lang } = useLocale()
  const { isAdmin } = useAuth()
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(false)

  const { useDashboardHomeThird } = useDashboardApi()
  const { useBillingGetCurrentSubscription } = useBillingApi()
  const { data: dataHomeThird, isLoading: isLoadingHomeThird } =
    useDashboardHomeThird()
  const { data: dataCurrentSubscription } = useBillingGetCurrentSubscription()

  const isLoading = useMemo(
    () => isLoadingInternal || isLoadingHomeThird,
    [isLoadingInternal, isLoadingHomeThird]
  )

  const currentPlan = useMemo(() => {
    if (!dataHomeThird?.hasActiveSubscription) {
      return {
        title: t?.currentPlan?.titleNoPlan,
        description: t?.currentPlan?.descriptionNoPlan,
        hiddenButton: !isAdmin,
        buttonLabel: t?.currentPlan?.buttonSubscribePlan,
        href: isAdmin ? t?.currentPlan?.href : undefined
      }
    }

    const userSeatProductType =
      dataHomeThird?.subscription?.userSeatProductType.toUpperCase()

    return {
      title: t?.currentPlan?.titleCurrentPlan,
      hasActiveSubscription: dataHomeThird?.hasActiveSubscription,
      plan: {
        badgePlan: t?.currentPlan?.typesPlan?.[userSeatProductType],
        planRecurrence:
          t?.currentPlan?.planRecurrence?.[
            dataHomeThird?.subscription?.recurrence
          ],
        renewsAt: `${t?.currentPlan?.renewsAtUtc} ${formatDate(
          dataHomeThird?.subscription
            ?.userSeatsLicenseValidUntilAtUtc as string,
          'date',
          lang
        )}`
      },
      hiddenButton: !isAdmin,
      buttonLabel: t?.currentPlan?.buttonUpdatePlan,
      href: t?.currentPlan?.href
    }
  }, [
    dataHomeThird?.hasActiveSubscription,
    dataHomeThird?.subscription?.recurrence,
    dataHomeThird?.subscription?.userSeatProductType,
    dataHomeThird?.subscription?.userSeatsLicenseValidUntilAtUtc,
    isAdmin,
    lang,
    t?.currentPlan?.buttonSubscribePlan,
    t?.currentPlan?.buttonUpdatePlan,
    t?.currentPlan?.descriptionNoPlan,
    t?.currentPlan?.href,
    t?.currentPlan?.planRecurrence,
    t?.currentPlan?.renewsAtUtc,
    t?.currentPlan?.titleCurrentPlan,
    t?.currentPlan?.titleNoPlan,
    t?.currentPlan?.typesPlan
  ])

  const dataCards = useMemo(() => {
    const mappingIconsCards = {
      pendingEnvelopes: <EnvelopeIcon className="w-6 h-6 text-orange-500" />,
      envelopesAvailable: (
        <DocumentTextIcon className="w-6 h-6 text-green-500" />
      ),
      vaults: <LockClosedIcon className="w-6 h-6 text-blue-500" />,
      contacts: <UserGroupIcon className="w-6 h-6 text-gray-500" />
    }
    const mappingValueCards = {
      pendingEnvelopes: dataHomeThird?.stats?.pendingEnvelopes,
      envelopesAvailable: dataHomeThird?.stats?.availableEnvelopes,
      vaults: dataHomeThird?.stats?.vaults,
      contacts: dataHomeThird?.stats?.contacts
    }

    return t?.cards?.map((card: any) => {
      const value = mappingValueCards[card.id as keyof typeof mappingValueCards]
      return {
        ...card,
        title: card.title,
        value: value === -1 ? t.unlimited : value === 0 ? t?.noValue : value,
        icon: mappingIconsCards[card.id as keyof typeof mappingIconsCards]
      }
    })
  }, [
    dataHomeThird?.stats?.availableEnvelopes,
    dataHomeThird?.stats?.contacts,
    dataHomeThird?.stats?.pendingEnvelopes,
    dataHomeThird?.stats?.vaults,
    t?.cards,
    t?.noValue,
    t.unlimited
  ])

  return {
    isLoading,
    dataCards,
    pendingInvoiceId: dataCurrentSubscription?.pendingInvoiceId,
    currentPlan,
    dataHomeThird
  }
}

export default useDashboardData
