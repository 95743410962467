import React from 'react'
import Link from 'next/link'
import { cn } from '@/lib/utils'
import Typography from '@/ui/atoms/Typography'

type Props = {
  id: string
  bgColor: string
  title: string
  value: number
  icon: JSX.Element
  href?: string
}

const CardDashboard: React.FC<Props> = ({
  id,
  title,
  bgColor,
  icon,
  value,
  href
}) => {
  const content = (
    <div
      className={cn(
        'relative flex items-start flex-1 gap-2 px-3 sm:px-4 py-4 sm:py-5 overflow-hidden transition-all bg-white dark:bg-gray-100 border border-gray-100 dark:border-gray-200 rounded-lg shadow sm:p-6',
        href &&
          'hover:shadow-md dark:hover:shadow-gray-100 hover:cursor-pointer'
      )}
    >
      <div className={cn('p-2 rounded-lg', bgColor)}>{icon}</div>
      <div className="flex flex-col">
        <Typography
          variant="text-base-medium"
          className="text-base text-gray-700 dark:text-gray-700"
        >
          {title}
        </Typography>
        <Typography
          variant="title-2xl-medium"
          className="text-gray-800 dark:text-gray-700"
        >
          {value}
        </Typography>
      </div>
    </div>
  )

  return href ? <Link href={href}>{content}</Link> : <>{content}</>
}

export default CardDashboard
