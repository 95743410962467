import * as React from 'react'

const IconEmptyNoDocuments = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.00 0.00 167.00 102.00"
      {...props}
    >
      <g strokeWidth={2.0} fill="none" strokeLinecap="butt">
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 126.03 15.10
    Q 127.17 21.53 126.57 28.99"
        />
        <path
          stroke="var(--secondary-700)"
          vectorEffect="non-scaling-stroke"
          d="
    M 126.77 34.26
    Q 125.86 36.19 126.69 38.41"
        />
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 126.69 42.99
    L 126.75 57.81"
        />
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 126.71 70.13
    Q 126.65 73.78 126.65 78.17
    Q 126.64 80.86 126.17 81.90
    Q 125.20 84.07 122.48 83.96
    Q 118.57 83.82 117.42 84.81"
        />
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 47.61 85.04
    L 44.17 57.00"
        />
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 42.40 42.98
    L 40.68 28.98"
        />
        <path
          stroke="var(--secondary)"
          vectorEffect="non-scaling-stroke"
          d="
    M 49.44 20.49
    C 49.06 21.26 48.62 21.76 47.69 21.87
    Q 45.40 22.13 43.18 22.43
    A 0.76 0.75 82.6 0 0 42.54 23.27
    L 51.55 96.65
    A 2.74 2.74 0.0 0 0 54.60 99.04
    Q 90.57 94.70 112.91 91.87
    C 116.60 91.40 115.42 87.56 115.08 84.68
    A 0.74 0.74 0.0 0 0 114.52 84.05
    Q 113.75 83.87 112.76 84.00"
        />
        <path
          stroke="var(--secondary-600)"
          vectorEffect="non-scaling-stroke"
          d="
    M 112.76 84.00
    Q 88.92 83.95 65.00 84.22
    Q 59.63 84.28 59.64 79.00
    Q 59.68 50.88 59.66 23.09"
        />
        <path
          stroke="var(--secondary)"
          vectorEffect="non-scaling-stroke"
          d="
    M 59.66 23.09
    L 59.58 15.48"
        />
        <path
          stroke="var(--secondary)"
          vectorEffect="non-scaling-stroke"
          d="
    M 120.86 18.09
    A 0.98 0.96 30.5 0 1 120.41 18.20
    Q 114.10 18.39 112.76 18.20
    Q 108.46 17.59 108.46 14.02
    Q 108.46 4.77 108.43 3.02
    Q 108.42 2.53 107.94 2.53
    L 63.69 2.53
    Q 62.16 2.53 62.16 4.07
    L 62.16 79.97
    A 1.57 1.57 0.0 0 0 63.73 81.54
    L 122.53 81.54
    A 1.64 1.64 0.0 0 0 124.17 79.90
    L 124.17 17.16
    A 0.96 0.91 23.7 0 0 123.89 16.50
    L 111.46 4.07
    Q 110.92 3.53 110.92 4.29
    L 110.92 14.15
    A 1.55 1.55 0.0 0 0 112.47 15.70
    L 120.04 15.70
    Q 121.19 15.70 121.76 16.70
    Q 122.19 17.45 120.86 18.09"
        />
        <path
          stroke="var(--secondary-200)"
          vectorEffect="non-scaling-stroke"
          d="
    M 98.19 16.97
    A 1.25 1.25 0.0 0 0 96.94 15.72
    L 71.10 15.72
    A 1.25 1.25 0.0 0 0 69.85 16.97
    L 69.85 17.03
    A 1.25 1.25 0.0 0 0 71.10 18.28
    L 96.94 18.28
    A 1.25 1.25 0.0 0 0 98.19 17.03
    L 98.19 16.97"
        />
        <path
          stroke="var(--secondary-200)"
          vectorEffect="non-scaling-stroke"
          d="
    M 115.20 28.89
    A 1.12 1.12 0.0 0 0 114.08 27.77
    L 71.00 27.77
    A 1.12 1.12 0.0 0 0 69.88 28.89
    L 69.88 29.09
    A 1.12 1.12 0.0 0 0 71.00 30.21
    L 114.08 30.21
    A 1.12 1.12 0.0 0 0 115.20 29.09
    L 115.20 28.89"
        />
        <path
          stroke="var(--secondary-200)"
          vectorEffect="non-scaling-stroke"
          d="
    M 115.19 41.90
    A 1.05 1.05 0.0 0 0 114.14 40.85
    L 70.96 40.85
    A 1.05 1.05 0.0 0 0 69.91 41.90
    L 69.91 42.10
    A 1.05 1.05 0.0 0 0 70.96 43.15
    L 114.14 43.15
    A 1.05 1.05 0.0 0 0 115.19 42.10
    L 115.19 41.90"
        />
        <path
          stroke="var(--secondary-200)"
          vectorEffect="non-scaling-stroke"
          d="
    M 115.16 54.85
    A 1.00 1.00 0.0 0 0 114.16 53.85
    L 70.88 53.85
    A 1.00 1.00 0.0 0 0 69.88 54.85
    L 69.88 55.15
    A 1.00 1.00 0.0 0 0 70.88 56.15
    L 114.16 56.15
    A 1.00 1.00 0.0 0 0 115.16 55.15
    L 115.16 54.85"
        />
        <path
          stroke="var(--secondary-200)"
          vectorEffect="non-scaling-stroke"
          d="
    M 98.07 67.92
    A 1.06 1.06 0.0 0 0 97.01 66.86
    L 70.85 66.86
    A 1.06 1.06 0.0 0 0 69.79 67.92
    L 69.79 68.04
    A 1.06 1.06 0.0 0 0 70.85 69.10
    L 97.01 69.10
    A 1.06 1.06 0.0 0 0 98.07 68.04
    L 98.07 67.92"
        />
        <path
          stroke="var(--secondary-100)"
          vectorEffect="non-scaling-stroke"
          d="
    M 59.66 23.09
    L 47.68 24.55
    Q 45.37 24.83 45.65 27.14
    L 53.77 93.21
    A 3.63 3.63 0.0 0 0 57.81 96.37
    Q 90.94 92.33 107.18 90.36
    C 109.52 90.07 112.21 90.06 113.08 87.55
    Q 113.66 85.89 112.76 84.00"
        />
        <path
          stroke="var(--secondary)"
          vectorEffect="non-scaling-stroke"
          d="
    M 50.32 20.43
    L 51.14 21.09
    A 1.35 1.31 -27.3 0 0 52.03 21.36
    Q 55.60 21.17 56.60 20.84
    Q 57.85 20.44 57.68 19.41
    Q 57.60 18.92 57.17 18.67
    L 56.24 18.14"
        />
      </g>
      <path
        fill="var(--secondary-700)"
        d="
    M 110.31 0.00
    L 126.03 15.10
    Q 127.17 21.53 126.57 28.99
    L 126.77 34.26
    Q 125.86 36.19 126.69 38.41
    L 126.69 42.99
    L 126.75 57.81
    L 126.71 70.13
    Q 126.65 73.78 126.65 78.17
    Q 126.64 80.86 126.17 81.90
    Q 125.20 84.07 122.48 83.96
    Q 118.57 83.82 117.42 84.81
    Q 118.17 87.70 118.09 89.00
    C 117.84 93.12 115.34 94.13 111.49 94.60
    Q 84.24 97.94 56.98 101.28
    Q 53.58 101.70 52.08 101.04
    Q 49.47 99.91 49.04 96.49
    Q 48.32 90.72 47.61 85.04
    L 44.17 57.00
    L 42.40 42.98
    L 40.68 28.98
    Q 40.43 26.29 40.13 23.72
    C 39.64 19.58 43.81 19.84 47.01 19.48
    Q 48.70 19.29 49.44 20.49
    C 49.06 21.26 48.62 21.76 47.69 21.87
    Q 45.40 22.13 43.18 22.43
    A 0.76 0.75 82.6 0 0 42.54 23.27
    L 51.55 96.65
    A 2.74 2.74 0.0 0 0 54.60 99.04
    Q 90.57 94.70 112.91 91.87
    C 116.60 91.40 115.42 87.56 115.08 84.68
    A 0.74 0.74 0.0 0 0 114.52 84.05
    Q 113.75 83.87 112.76 84.00
    Q 88.92 83.95 65.00 84.22
    Q 59.63 84.28 59.64 79.00
    Q 59.68 50.88 59.66 23.09
    L 59.58 15.48
    Q 59.81 8.06 59.67 5.75
    Q 59.39 1.29 62.90 0.00
    L 110.31 0.00
    Z
    M 120.86 18.09
    A 0.98 0.96 30.5 0 1 120.41 18.20
    Q 114.10 18.39 112.76 18.20
    Q 108.46 17.59 108.46 14.02
    Q 108.46 4.77 108.43 3.02
    Q 108.42 2.53 107.94 2.53
    L 63.69 2.53
    Q 62.16 2.53 62.16 4.07
    L 62.16 79.97
    A 1.57 1.57 0.0 0 0 63.73 81.54
    L 122.53 81.54
    A 1.64 1.64 0.0 0 0 124.17 79.90
    L 124.17 17.16
    A 0.96 0.91 23.7 0 0 123.89 16.50
    L 111.46 4.07
    Q 110.92 3.53 110.92 4.29
    L 110.92 14.15
    A 1.55 1.55 0.0 0 0 112.47 15.70
    L 120.04 15.70
    Q 121.19 15.70 121.76 16.70
    Q 122.19 17.45 120.86 18.09
    Z"
      />
      <path
        fill="#ffffff"
        d="
    M 120.86 18.09
    Q 122.19 17.45 121.76 16.70
    Q 121.19 15.70 120.04 15.70
    L 112.47 15.70
    A 1.55 1.55 0.0 0 1 110.92 14.15
    L 110.92 4.29
    Q 110.92 3.53 111.46 4.07
    L 123.89 16.50
    A 0.96 0.91 23.7 0 1 124.17 17.16
    L 124.17 79.90
    A 1.64 1.64 0.0 0 1 122.53 81.54
    L 63.73 81.54
    A 1.57 1.57 0.0 0 1 62.16 79.97
    L 62.16 4.07
    Q 62.16 2.53 63.69 2.53
    L 107.94 2.53
    Q 108.42 2.53 108.43 3.02
    Q 108.46 4.77 108.46 14.02
    Q 108.46 17.59 112.76 18.20
    Q 114.10 18.39 120.41 18.20
    A 0.98 0.96 30.5 0 0 120.86 18.09
    Z
    M 98.19 16.97
    A 1.25 1.25 0.0 0 0 96.94 15.72
    L 71.10 15.72
    A 1.25 1.25 0.0 0 0 69.85 16.97
    L 69.85 17.03
    A 1.25 1.25 0.0 0 0 71.10 18.28
    L 96.94 18.28
    A 1.25 1.25 0.0 0 0 98.19 17.03
    L 98.19 16.97
    Z
    M 115.20 28.89
    A 1.12 1.12 0.0 0 0 114.08 27.77
    L 71.00 27.77
    A 1.12 1.12 0.0 0 0 69.88 28.89
    L 69.88 29.09
    A 1.12 1.12 0.0 0 0 71.00 30.21
    L 114.08 30.21
    A 1.12 1.12 0.0 0 0 115.20 29.09
    L 115.20 28.89
    Z
    M 115.19 41.90
    A 1.05 1.05 0.0 0 0 114.14 40.85
    L 70.96 40.85
    A 1.05 1.05 0.0 0 0 69.91 41.90
    L 69.91 42.10
    A 1.05 1.05 0.0 0 0 70.96 43.15
    L 114.14 43.15
    A 1.05 1.05 0.0 0 0 115.19 42.10
    L 115.19 41.90
    Z
    M 115.16 54.85
    A 1.00 1.00 0.0 0 0 114.16 53.85
    L 70.88 53.85
    A 1.00 1.00 0.0 0 0 69.88 54.85
    L 69.88 55.15
    A 1.00 1.00 0.0 0 0 70.88 56.15
    L 114.16 56.15
    A 1.00 1.00 0.0 0 0 115.16 55.15
    L 115.16 54.85
    Z
    M 98.07 67.92
    A 1.06 1.06 0.0 0 0 97.01 66.86
    L 70.85 66.86
    A 1.06 1.06 0.0 0 0 69.79 67.92
    L 69.79 68.04
    A 1.06 1.06 0.0 0 0 70.85 69.10
    L 97.01 69.10
    A 1.06 1.06 0.0 0 0 98.07 68.04
    L 98.07 67.92
    Z"
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 167.00 20.79
    L 167.00 23.09
    Q 165.39 28.92 159.50 28.94
    Q 143.33 29.01 126.57 28.99
    Q 127.17 21.53 126.03 15.10
    Q 142.67 14.93 159.50 15.02
    Q 165.30 15.06 167.00 20.79
    Z"
      />
      <path
        fill="#ffffff"
        d="
    M 59.58 15.48
    L 59.66 23.09
    L 47.68 24.55
    Q 45.37 24.83 45.65 27.14
    L 53.77 93.21
    A 3.63 3.63 0.0 0 0 57.81 96.37
    Q 90.94 92.33 107.18 90.36
    C 109.52 90.07 112.21 90.06 113.08 87.55
    Q 113.66 85.89 112.76 84.00
    Q 113.75 83.87 114.52 84.05
    A 0.74 0.74 0.0 0 1 115.08 84.68
    C 115.42 87.56 116.60 91.40 112.91 91.87
    Q 90.57 94.70 54.60 99.04
    A 2.74 2.74 0.0 0 1 51.55 96.65
    L 42.54 23.27
    A 0.76 0.75 82.6 0 1 43.18 22.43
    Q 45.40 22.13 47.69 21.87
    C 48.62 21.76 49.06 21.26 49.44 20.49
    L 50.32 20.43
    L 51.14 21.09
    A 1.35 1.31 -27.3 0 0 52.03 21.36
    Q 55.60 21.17 56.60 20.84
    Q 57.85 20.44 57.68 19.41
    Q 57.60 18.92 57.17 18.67
    L 56.24 18.14
    Q 57.47 16.26 59.58 15.48
    Z"
      />
      <rect
        fill="var(--secondary"
        x={69.85}
        y={15.72}
        width={28.34}
        height={2.56}
        rx={1.25}
      />
      <path
        fill="var(--secondary-700)"
        d="
    M 56.24 18.14
    L 57.17 18.67
    Q 57.60 18.92 57.68 19.41
    Q 57.85 20.44 56.60 20.84
    Q 55.60 21.17 52.03 21.36
    A 1.35 1.31 -27.3 0 1 51.14 21.09
    L 50.32 20.43
    Q 50.81 19.04 51.75 18.94
    Q 54.97 18.57 56.24 18.14
    Z"
      />
      <path
        fill="var(--secondary-200)"
        d="
    M 59.66 23.09
    Q 59.68 50.88 59.64 79.00
    Q 59.63 84.28 65.00 84.22
    Q 88.92 83.95 112.76 84.00
    Q 113.66 85.89 113.08 87.55
    C 112.21 90.06 109.52 90.07 107.18 90.36
    Q 90.94 92.33 57.81 96.37
    A 3.63 3.63 0.0 0 1 53.77 93.21
    L 45.65 27.14
    Q 45.37 24.83 47.68 24.55
    L 59.66 23.09
    Z"
      />
      <rect
        fill="var(--secondary"
        x={69.88}
        y={27.77}
        width={45.32}
        height={2.44}
        rx={1.12}
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 40.68 28.98
    L 42.40 42.98
    Q 32.96 43.03 23.79 42.98
    Q 20.08 42.96 18.51 42.06
    C 14.82 39.92 13.92 34.94 16.56 31.62
    Q 18.59 29.06 22.50 29.05
    Q 31.42 29.03 40.68 28.98
    Z"
      />
      <path
        fill="var(--secondary"
        d="
    M 126.77 34.26
    Q 127.24 36.62 126.69 38.41
    Q 125.86 36.19 126.77 34.26
    Z"
      />
      <rect
        fill="var(--secondary"
        x={69.91}
        y={40.85}
        width={45.28}
        height={2.3}
        rx={1.05}
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 126.75 57.81
    L 126.69 42.99
    Q 133.41 43.00 139.99 43.01
    Q 143.82 43.02 145.34 43.85
    C 150.34 46.58 150.13 54.04 144.78 56.37
    Q 143.51 56.93 141.02 56.87
    Q 133.95 56.71 126.75 57.81
    Z"
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 167.00 48.78
    L 167.00 51.10
    C 164.43 61.18 150.36 56.90 153.46 47.70
    Q 154.32 45.14 156.66 43.88
    C 161.09 41.50 165.57 44.09 167.00 48.78
    Z"
      />
      <rect
        fill="var(--secondary"
        x={69.88}
        y={53.85}
        width={45.28}
        height={2.3}
        rx={1.0}
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 44.17 57.00
    L 47.61 85.04
    Q 39.97 85.12 39.15 78.77
    C 38.77 75.86 40.34 72.76 43.03 71.77
    Q 45.04 71.02 42.84 71.02
    Q 29.09 70.97 7.75 71.02
    Q 1.74 71.04 0.00 65.22
    L 0.00 62.89
    Q 1.54 57.08 6.50 57.06
    Q 25.18 56.96 44.17 57.00
    Z"
      />
      <rect
        fill="var(--secondary"
        x={69.79}
        y={66.86}
        width={28.28}
        height={2.24}
        rx={1.06}
      />
      <path
        fill="var(--secondary-100)"
        d="
    M 126.71 70.13
    Q 134.22 71.14 135.62 76.09
    Q 136.52 79.27 134.73 81.94
    Q 132.83 84.78 128.75 84.93
    Q 123.31 85.13 117.42 84.81
    Q 118.57 83.82 122.48 83.96
    Q 125.20 84.07 126.17 81.90
    Q 126.64 80.86 126.65 78.17
    Q 126.65 73.78 126.71 70.13
    Z"
      />
    </svg>
  )
}

export default IconEmptyNoDocuments
