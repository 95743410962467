import { useMemo } from 'react'
import useTranslation from '@/hooks/useTranslation'
import useDashboardData from '@/hooks/useDashboardData/useDashboardData'
import Typography from '@/ui/atoms/Typography'
import BannerDashboard from '@/ui/molecules/BannerDashboard'
import Breadcrumb from '@/ui/molecules/Breadcrumb'
import CardDashboard from '@/ui/molecules/CardDashboard'
import SectionUploadDocuments from '@/ui/molecules/SectionUploadDocuments'

const DashboardPage = () => {
  const { t, isReady } = useTranslation('dashboardPage')
  const { dataCards, pendingInvoiceId, currentPlan, dataHomeThird, isLoading } =
    useDashboardData()

  const breadcrumbs = [{ name: t?.title, href: '/', current: true }]

  const showSkeleton = useMemo(
    () => !isReady || isLoading,
    [isReady, isLoading]
  )

  return (
    <div className="bg-gray-50 flex flex-col min-h-[calc(100vh-64px)] h-full">
      <Breadcrumb items={breadcrumbs} showSkeleton={!isReady} />
      <div className="relative flex flex-col items-center flex-1 h-full gap-5 container-main">
        <div className="w-full pt-5 sm:pt-6 md:pt-8">
          <Typography
            variant="title-2xl-medium"
            className="text-gray-700 dark:text-gray-700"
            type="h1"
            showSkeleton={showSkeleton}
            classNameSkeleton="w-24"
          >
            {t?.title}
          </Typography>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 sm:gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {showSkeleton || !dataCards
            ? Array.from({ length: 4 }, (_, index) => (
                <div
                  className="w-full h-[106px] skeleton"
                  key={`skeleton-${index}`}
                />
              ))
            : dataCards?.map((item) => (
                <CardDashboard key={item.id} {...item} />
              ))}
        </div>
        <SectionUploadDocuments showSkeleton={showSkeleton} />
        <BannerDashboard
          pendingInvoiceId={pendingInvoiceId as string}
          currentPlan={currentPlan}
          showSkeleton={showSkeleton}
          {...dataHomeThird}
        />
      </div>
    </div>
  )
}

export default DashboardPage
