import { NextSeo } from 'next-seo'
import useTranslation from '@/hooks/useTranslation'
import DashboardPage from '@/ui/pages/dashboardPage'
import LoggedInAreaTemplate from '@/ui/templates/LoggedInAreaTemplate'

export default function Home() {
  const { t } = useTranslation('seo')

  return (
    <LoggedInAreaTemplate>
      <DashboardPage />
      <NextSeo title={t?.titles?.dashboard} />
    </LoggedInAreaTemplate>
  )
}
