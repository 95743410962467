import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  BillingApiEnvelopeRequest,
  BillingApiEnvelopeRequestWithId,
  BillingApiEnvelopeResponse,
  BillingChangeSubscriptionRequest,
  BillingChangeSubscriptionRequestWithId,
  BillingChangeSubscriptionResponse,
  BillingCreateCheckoutSubscriptionRequest,
  BillingCurrentSubscriptionResponse,
  BillingGetLinkChangePaymentMethodRequest,
  BillingGetLinkChangePaymentMethodResponse,
  BillingGetLinkCheckoutResponse,
  BillingGetLinkNfeResponse,
  BillingGetLinkPendingInvoiceResponse,
  BillingGetLinkReceiptResponse,
  BillingGetListAvailablePlansResponse,
  BillingInvoices,
  BillingMfaCreditsRequest,
  BillingMfaCreditsRequestWithId,
  BillingMfaCreditsResponse,
  BillingUpdateUserSeatsRequest,
  BillingUpdateUserSeatsRequestWithId
} from '@/hooks/api/billing/useBillingApi/useBillingApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useBillingApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { isLogged, isAdmin } = useAuth()
  const queryClient = useQueryClient()

  const useBillingInvoices = (pageNumber = 0, pageSize = 10) => {
    return useQuery({
      queryKey: [QueryKeys.BillingInvoices, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, BillingInvoices>(
          `/billing/invoices?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(isLogged && isAdmin)
    })
  }

  const useBillingGetLinkCheckout = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        handleApi<void, BillingGetLinkCheckoutResponse>(
          `/billing/invoices/${id}/checkout`,
          'GET'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkCheckout]
        })
      }
    })

  const useBillingGetLinkReceipt = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        handleApi<void, BillingGetLinkReceiptResponse>(
          `/billing/invoices/${id}/receipt`,
          'GET'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkReceipt]
        })
      }
    })

  const useBillingGetLinkPendingInvoice = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        handleApi<void, BillingGetLinkPendingInvoiceResponse>(
          `/billing/invoices/${id}/checkout`,
          'GET'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkPendingInvoice]
        })
      }
    })

  const useBillingGetLinkNfe = () =>
    useMutation({
      mutationFn: ({ id }: { id: string }) =>
        handleApi<void, BillingGetLinkNfeResponse>(
          `/billing/invoices/${id}/nfe`,
          'GET'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkNfe]
        })
      }
    })

  const useBillingGetCurrentSubscription = (enablePolling = false) => {
    const query = useQuery({
      queryKey: [QueryKeys.BillingGetCurrentSubscription],
      queryFn: () =>
        handleApi<void, BillingCurrentSubscriptionResponse>(
          `/billing/subscriptions`,
          'GET'
        ),
      ...queryConfig,
      refetchInterval: enablePolling ? 5000 : false,
      refetchOnWindowFocus: true,
      enabled: Boolean(isLogged && isAdmin)
    })

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          query.refetch()
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }, [query])

    return query
  }

  const useBillingGetListAvailablePlans = () =>
    useQuery({
      queryKey: [QueryKeys.BillingGetListAvailablePlans],
      queryFn: () =>
        handleApi<void, BillingGetListAvailablePlansResponse>(
          `/billing/plans`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(isLogged && isAdmin)
    })

  const useBillingDeleteSubscription = () =>
    useMutation({
      mutationFn: ({ id }: { id?: string }) =>
        handleApi<void, BillingCurrentSubscriptionResponse>(
          `/billing/subscriptions/${id}`,
          'DELETE'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingReleaseSubscription = () =>
    useMutation({
      mutationFn: ({ id }: { id?: string }) =>
        handleApi<void, BillingCurrentSubscriptionResponse>(
          `/billing/subscriptions/${id}/release`,
          'POST'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingChangeSubscription = () =>
    useMutation({
      mutationFn: ({ id, ...data }: BillingChangeSubscriptionRequestWithId) =>
        handleApi<
          BillingChangeSubscriptionRequest,
          BillingChangeSubscriptionResponse
        >(`/billing/subscriptions/${id}/plan`, 'PATCH', data),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingGetCheckoutSignerMfaCredits = () =>
    useMutation({
      mutationFn: ({ id, ...data }: BillingMfaCreditsRequestWithId) =>
        handleApi<BillingMfaCreditsRequest, BillingMfaCreditsResponse>(
          `/billing/subscriptions/${id}/signer-mfa-credits/checkout`,
          'POST',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCheckoutMfaCredits]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingGetCheckoutApiEnvelope = () =>
    useMutation({
      mutationFn: ({ id, ...data }: BillingApiEnvelopeRequestWithId) =>
        handleApi<BillingApiEnvelopeRequest, BillingApiEnvelopeResponse>(
          `/billing/subscriptions/${id}/api-envelopes/checkout`,
          'POST',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCheckoutMfaCredits]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingCreateCheckoutSubscription = () =>
    useMutation({
      mutationFn: (data: BillingCreateCheckoutSubscriptionRequest) =>
        handleApi<
          BillingCreateCheckoutSubscriptionRequest,
          BillingGetLinkCheckoutResponse
        >(`/billing/subscriptions/checkout`, 'POST', data),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkCheckout]
        })
      }
    })

  const useBillingUpdateAddonApiEnvelope = () =>
    useMutation({
      mutationFn: ({
        id,
        type
      }: {
        id?: string
        type: 'activate' | 'deactivate'
      }) =>
        handleApi<void, void>(
          `/billing/subscriptions/${id}/addons/api/${type}`,
          'POST'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingUpdateAddonApiEnvelope]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingUpdateAddonWhiteLabel = () =>
    useMutation({
      mutationFn: ({
        id,
        type
      }: {
        id?: string
        type: 'activate' | 'deactivate'
      }) =>
        handleApi<void, void>(
          `/billing/subscriptions/${id}/addons/white-label/${type}`,
          'POST'
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingUpdateAddonWhiteLabel]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingUpdateUserSeats = () =>
    useMutation({
      mutationFn: ({ id, userSeats }: BillingUpdateUserSeatsRequestWithId) =>
        handleApi<BillingUpdateUserSeatsRequest, void>(
          `/billing/subscriptions/${id}/seats`,
          'PATCH',
          {
            userSeats
          }
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetCurrentSubscription]
        })
      }
    })

  const useBillingGetLinkChangePaymentMethod = () =>
    useMutation({
      mutationFn: (data: BillingGetLinkChangePaymentMethodRequest) =>
        handleApi<
          BillingGetLinkChangePaymentMethodRequest,
          BillingGetLinkChangePaymentMethodResponse
        >(`/billing/portal/url/change-payment-method`, 'POST', data),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.BillingGetLinkChangePaymentMethod]
        })
      }
    })

  return {
    useBillingInvoices,
    useBillingGetLinkCheckout,
    useBillingGetLinkReceipt,
    useBillingGetLinkNfe,
    useBillingGetCurrentSubscription,
    useBillingGetListAvailablePlans,
    useBillingDeleteSubscription,
    useBillingReleaseSubscription,
    useBillingChangeSubscription,
    useBillingGetCheckoutSignerMfaCredits,
    useBillingGetCheckoutApiEnvelope,
    useBillingCreateCheckoutSubscription,
    useBillingUpdateAddonApiEnvelope,
    useBillingUpdateAddonWhiteLabel,
    useBillingUpdateUserSeats,
    useBillingGetLinkPendingInvoice,
    useBillingGetLinkChangePaymentMethod
  }
}

export default useBillingApi
