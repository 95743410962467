import { useQuery } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  DashboardHomeStatisticsAccountResponse,
  DashboardHomeStatisticsUserAccountResponse,
  GetDashboardHomeThirdResponse
} from '@/hooks/api/dashboards/useDashboardApi/useDashboardApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useDashboardApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { authMetadata } = useAuth()

  const useDashboardHomeStatisticsUserAccount = (enabled: boolean) => {
    return useQuery({
      queryKey: [QueryKeys.DashboardHomeStatisticsUserAccount],
      queryFn: () =>
        handleApi<void, DashboardHomeStatisticsUserAccountResponse>(
          '/dashboards/home/statistics/user-account',
          'GET'
        ),
      ...queryConfig,
      enabled: enabled
    })
  }

  const useDashboardHomeStatisticsAccount = (enabled: boolean) => {
    return useQuery({
      queryKey: [QueryKeys.DashboardHomeStatisticsAccount],
      queryFn: () =>
        handleApi<void, DashboardHomeStatisticsAccountResponse>(
          '/dashboards/home/statistics/account',
          'GET'
        ),
      ...queryConfig,
      enabled: enabled
    })
  }

  const useDashboardHomeThird = () =>
    useQuery({
      queryKey: [QueryKeys.DashboardHomeThird],
      queryFn: () =>
        handleApi<void, GetDashboardHomeThirdResponse>(
          '/dashboards/home/third',
          'GET'
        ),
      ...queryConfig,
      enabled: !!authMetadata?.accessToken
    })

  return {
    useDashboardHomeStatisticsUserAccount,
    useDashboardHomeStatisticsAccount,
    useDashboardHomeThird
  }
}

export default useDashboardApi
