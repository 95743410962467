import React, { useCallback, useRef, useState } from 'react'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'
import useTranslation from '@/hooks/useTranslation'
import api from '@/services/api'
import { useAuth } from '@/providers/Auth'
import { useLocale } from '@/providers/Locale'
import { useEnvelopeApi } from '@/hooks/api/ecm/useEnvelopeApi'
import { DocumentEnvelope } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { cn } from '@/lib/utils'
import { StepEnum } from '@/providers/EnvelopeV2/EnvelopeV2.types'
import Button from '@/ui/atoms/Button'
import IconEmptyNoDocuments from '@/ui/atoms/Icons/IconEmptyNoDocuments'
import LoadingSpinner from '@/ui/atoms/LoadingSpinner'
import { showToast } from '@/ui/atoms/Toast'
import Typography from '@/ui/atoms/Typography'

type Props = {
  showSkeleton?: boolean
}

const SectionUploadDocuments: React.FC<Props> = ({ showSkeleton = false }) => {
  const { t, isReady } = useTranslation(['uploadDocuments', 'envelope'], true)
  const { push } = useRouter()
  const [documents, setDocuments] = useState<DocumentEnvelope[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { lang } = useLocale()
  const { authMetadata, userContext } = useAuth()
  const { useGetFirstVault } = useVaultApi()
  const { data: dataFirstVault, isFetched: isFetchedFirstVault } =
    useGetFirstVault(true)
  const { useCreateEnvelope } = useEnvelopeApi()
  const {
    mutateAsync: mutateAsyncCreateEnvelope,
    isLoading: isLoadingCreateEnvelope
  } = useCreateEnvelope()

  const handleCreateAndRedirectEnvelope = useCallback(
    async (documents) => {
      if (isFetchedFirstVault && dataFirstVault) {
        const envelopePayload = {
          vaultId: dataFirstVault.items[0].vaultId,
          name: 'New envelope',
          message: '',
          privateDescription: '',
          publicDescription: '',
          reviewReminder: true,
          toBePublished: false,
          toBePublishedAtUtc: null,
          hasExpiration: false,
          expirationReminder: false,
          expiresAtUtc: null,
          language: lang,
          signInOrder: true,
          positionSignatureMarks: false,
          markupOrientation: 'Bottom',
          documents: documents,
          signers: [
            {
              id: 'fake-id-' + uuidv4(),
              name: userContext?.userAccountInformation?.name,
              email: userContext?.userAccountInformation?.email,
              role: 'Sign',
              title: null,
              shouldEnforceEmailValidation: true,
              shouldEnforcePasscodeValidation: false,
              passcode: null,
              passcodeHint: null,
              shouldEnforceSmsValidation: false,
              phoneNumber: null,
              phoneIdd: null,
              signMarks: [],
              index: 0
            }
          ]
        } as any

        try {
          const response = await mutateAsyncCreateEnvelope(envelopePayload)

          if (response?.envelopeId) {
            push(
              `/envelope/${response?.envelopeId}/edit?step=${StepEnum.DOCUMENTS}&origin=dashboard`
            )
          }
        } catch (error) {
          console.error(error)
          showToast.error(t?.toasts?.errorCreateEnvelope)
        }
      }
    },
    [
      dataFirstVault,
      isFetchedFirstVault,
      lang,
      mutateAsyncCreateEnvelope,
      push,
      t?.toasts?.errorCreateEnvelope,
      userContext?.userAccountInformation?.email,
      userContext?.userAccountInformation?.name
    ]
  )

  const handleDocumentsChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedDocuments = Array.from(event.target.files || [])

      if (documents.length + uploadedDocuments.length > 10) {
        showToast.error(t?.errorMaxFiles)
        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }
        return
      }

      if (uploadedDocuments.length > 0) {
        const isValidFile = uploadedDocuments.every(
          (file) => file.type === 'application/pdf' && file.size <= 26214400 // 25MB
        )
        if (!isValidFile) {
          showToast.error(t?.errorSizeFile)
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
          }
          return
        }

        if (inputFileRef.current) {
          inputFileRef.current.value = ''
        }

        setIsLoading(true)

        const uploadPromises = uploadedDocuments.map((file) => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('language', lang)
          formData.append('markupOrientation', 'Bottom')

          return api.post('/ecm/documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authMetadata?.accessToken}`,
              ...(userContext?.userAccountInformation?.id && {
                'X-Signater-UserAccountId':
                  userContext?.userAccountInformation?.id
              })
            }
          })
        })

        try {
          const responses = await Promise.all(uploadPromises)

          const newDocuments = responses.map((response) => ({
            id: response.data.documentId,
            name: response.data.name,
            description: response.data.description,
            pageSizes: response.data.pageSizes
          }))

          const updatedDocuments = [...documents, ...newDocuments]
          setDocuments(updatedDocuments as DocumentEnvelope[])

          handleCreateAndRedirectEnvelope(updatedDocuments)
          // onSelectedDocuments(documentIds)
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [
      documents,
      t?.errorMaxFiles,
      t?.errorSizeFile,
      lang,
      authMetadata?.accessToken,
      userContext?.userAccountInformation?.id,
      handleCreateAndRedirectEnvelope
    ]
  )

  const handleOnDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()

      const uploadedDocuments = Array.from(e.dataTransfer.files)

      const eventMock = {
        target: {
          files: uploadedDocuments
        }
      } as any

      await handleDocumentsChange(eventMock)
    },
    [handleDocumentsChange]
  )

  const handleOnDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }, [])

  const handleOnDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
    },
    []
  )

  if (!isReady || showSkeleton) {
    return <div className="skeleton h-[400px] w-full" />
  }

  return (
    <div
      className={cn(
        'flex flex-col flex-1 w-full h-full mb-2',
        isLoading && 'opacity-50 pointer-events-none'
      )}
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
      onDragEnter={handleOnDragEnter}
    >
      <label
        htmlFor="fileInput"
        className="flex flex-col items-center justify-center w-full gap-2 px-6 py-8 border border-dashed rounded-lg cursor-pointer border-secondary-200 hover:border-secondary-700 dark:border-gray-600 hover:dark:border-gray-950 transition-all duration-500 bg-secondary-50 dark:bg-gray-100 h-[300px] md:h-[400px]"
      >
        {isLoading || isLoadingCreateEnvelope ? (
          <div className="h-[100px] flex items-center justify-center w-full">
            <LoadingSpinner />
          </div>
        ) : (
          !documents?.length && (
            <div className="flex items-center justify-center w-full rounded-sm">
              <IconEmptyNoDocuments className="w-[140px]" />
            </div>
          )
        )}
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleDocumentsChange}
          ref={inputFileRef}
          className="hidden"
          id="fileInput"
          disabled={isLoading}
        />
        <div className="flex flex-col items-center justify-center gap-2 mt-5">
          <Button
            variant="secondary"
            className="w-fit"
            onClick={() => inputFileRef.current?.click()}
            disabled={isLoading}
          >
            <ArrowUpTrayIcon className="w-5 h-5 text-white" />
            {t?.buttonAddDocuments}
          </Button>
          <div className="flex items-center justify-center gap-4 w-fit">
            <div className="flex flex-col items-center">
              <Typography
                variant="title-lg-regular"
                className="text-base text-center text-gray-950 font-display w-fit dark:text-gray-700"
                showSkeleton={!isReady}
              >
                {t?.labelDragDocuments}
              </Typography>
              <Typography
                variant="text-sm-regular"
                className="text-center text-gray-400 dark:text-gray-600 w-fit"
                showSkeleton={!isReady}
              >
                {t?.fileSize}
              </Typography>
            </div>
          </div>
        </div>
      </label>
    </div>
  )
}

export default SectionUploadDocuments
